let menuHeight = 550;
let isCollapsed = false;
let isReady = false;
let isMovingToTop = false;

let megamenu, fixedHeader, wrapper;

function closeMenu({ hideMegaMenu = false } = {}) {
  console.log('closing menu')
  if (hideMegaMenu) megamenu.classList.add("collapsed-nav");
  fixedHeader.classList.add("collapsed-nav");
  menuHeight = megamenu.offsetHeight;
  console.log(menuHeight);
  isCollapsed = true;
}

function openMenu() {
  console.log('opening menu')
  megamenu.classList.remove("collapsed-nav");
  fixedHeader.classList.remove("collapsed-nav");
  menuHeight = megamenu.offsetHeight;
  console.log(menuHeight);
  isCollapsed = false;
}

window.addEventListener("load", function() {
  megamenu = document.getElementById("megamenu");
  fixedHeader = document.getElementById("header-fixed");
  wrapper = document.getElementById("or-nav-wrapper");
  menuHeight = megamenu.offsetHeight;
  isCollapsed = wrapper.classList.contains("collapsed");
  isReady = true;
});

window.addEventListener("scroll", function() {
  if (!isReady || isMovingToTop) return;
  if (window.scrollY > menuHeight) {
    fixedHeader.classList.add("collapsed-nav");
  } else if (window.scrollY < menuHeight) {
    fixedHeader.classList.remove("collapsed-nav");
  }
});

function moveToTop() {
  if (isMovingToTop) return;
  isMovingToTop = true;
  setTimeout(() => {
    isMovingToTop = false;
  }, 1000);
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

document.getElementById("menu-button").addEventListener("click", function(e) {
  e.preventDefault();
  if (isCollapsed) {
    moveToTop();
    openMenu();
  } else {
    if (window.scrollY < 400) {
      console.log('closing')
      closeMenu({ hideMegaMenu: true });
    } else {
      console.log('scrolling to top because isCollapsed is false and window is greater than 400')
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
});
