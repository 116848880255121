/* CSS */
import "@js/parts/css";

/* JS */
//import "core-js/stable"
//import "regenerator-runtime/runtime"
import "@js/parts/lazyloading";
import "@js/parts/fadeIn";
import UIkit from 'uikit';
import 'flowbite';
import "@js/parts/navigation";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();
